<template>
  <v-dialog
    :value="mostrar"
    content-class="dialog-pesquisar-pessoa"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-toolbar
        color="grey lighten-3"
        flat
        dense
      >
        <v-toolbar-title>
          Pesquisar pessoa
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            small
            @click="$emit('fechar')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-7">
        <v-row>
          <v-col cols="11">
            <autocomplete-pessoas
              limpar-apos-selecionar
              :pessoa-adicionada="pessoaAdicionada"
              @change="(pessoa) => $emit('change', pessoa)"
            />
          </v-col>

          <v-col
            cols="1"
            class="text-left pl-0"
          >
            <v-btn
              icon
              color="success"
              @click="mostrarDialogPessoa = true"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </v-col>

          <dialog-pessoa
            :mostrar="mostrarDialogPessoa"
            @fechar="mostrarDialogPessoa = false"
            @salvar:dados="salvarPessoa"
          />
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {

    components: {
      AutocompletePessoas: () => import('@/components/input/InputAutocompletePessoas'),
      DialogPessoa: () => import('@/components/dialog/pessoa/DialogPessoa'),
    },

    props: {
      mostrar: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        mostrarDialogPessoa: null,
        pessoaAdicionada: null,
      }
    },

    methods: {
      salvarPessoa (pessoaSalva) {
        this.mostrarDialogPessoa = false
        this.pessoaAdicionada = pessoaSalva
      },
    },

  }
</script>

<style lang="css">
  .dialog-pesquisar-pessoa {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .dialog-pesquisar-pessoa {
      width: 50% !important;
    }
  }
</style>
